import { Typography } from "@mui/material";

const LabelValueText = ({ label, value }) => {
  return (
    <div style={{ margin: "5px 0px" }}>
      <Typography variant="body1">
        <strong>{label}</strong>
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );
};

export default LabelValueText;
