import React from "react";
import { Typography, Box } from "@mui/material";

const Title = ({ title }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 1,
        mb: 1,
        pt: 1,
        pb: 1,
      }}
    >
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
