import { Map, Marker } from "@vis.gl/react-google-maps";

import BottomDrawer from "./BottomDrawer";

const MapLocationViewDrawer = ({ isOpen, setIsOpen, latitude, longitude }) => {
  const location = {
    lat: latitude,
    lng: longitude,
  };
  return (
    <BottomDrawer isOpen={isOpen} setIsOpen={setIsOpen} title={"Location"}>
      <Map defaultCenter={location} defaultZoom={18}>
        <Marker position={location} />
      </Map>
    </BottomDrawer>
  );
};

export default MapLocationViewDrawer;
