import { Box, Pagination as MuiPagination } from "@mui/material";

const Pagination = ({ page, setPage, totalCount, rowsPerPage }) => {
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <MuiPagination
        count={pageCount}
        page={page + 1}
        onChange={handlePageChange}
        color="primary"
      />
    </Box>
  );
};

export default Pagination;
