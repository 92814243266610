import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";

const ProcessAllOrders = ({ fetchOrders }) => {
  const processAllOrders = async () => {
    try {
      await api.orders.processAllExpiredOrders();
      await fetchOrders();
      toast.success("All orders processed successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      fullWidth
      variant="contained"
      color="success"
      onClick={processAllOrders}
      endIcon={<CheckIcon />}
    >
      Process All Orders
    </Button>
  );
};

export default ProcessAllOrders;
