import {
  Alert,
  Card,
  CardContent,
  Container,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CategoryTitle from "../../CategoryTitle";
import BottomButton from "../../BottomButton";

import generatePricingCategories from "../../../utils/generatePricingCategories";
import { useData } from "../../../context/data-context";
import withDataCheck from "../../../hoc/withDataCheck";

const PricePreviewDrawer = ({
  isOpen,
  closeDrawer,
  configuration,
  pricing,
  continueHandler,
  showCloseButton,
}) => {
  const data = useData();
  const { cuisines, mealTypes, mealTimes, periods } = configuration;

  const categories = generatePricingCategories({
    data,
    cuisines,
    mealTypes,
    mealTimes,
    periods,
    pricing,
  });

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={closeDrawer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
          position: "sticky",
          top: 0,
          padding: "16px",
          backgroundColor: "#eee",
        }}
      >
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Price Preview
        </Typography>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </div>

      <Container style={{ height: "60vh" }}>
        <div style={{ paddingBottom: "50px" }}>
          <Alert severity="info" style={{ marginBottom: "16px" }}>
            5 % of the below prices will be deducted as platform comission
          </Alert>
          {categories.map((category, index) => (
            <Card key={index} sx={{ margin: "5px 0px" }}>
              <CardContent>
                <CategoryTitle
                  cuisine={category.cuisine}
                  mealType={category.mealType}
                  mealTime={category.mealTime}
                  period={category.period}
                />
                <Typography variant="body1">
                  Price: ₹{category.price}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>
      {showCloseButton ? (
        <BottomButton onClick={closeDrawer} isArrowHidden>
          Close
        </BottomButton>
      ) : (
        <BottomButton onClick={continueHandler}>Bank Details</BottomButton>
      )}
    </Drawer>
  );
};

export default withDataCheck(PricePreviewDrawer);
