import setupAxios from "../utils/setupAxios";

import auth from "./auth";
import users from "./users";
import kitchenRegistrations from "./kitchenRegistrations";
import data from "./data";
import payments from "./payments";
import settlements from "./settlements";
import refunds from "./refunds";
import orders from "./orders";
import deliveries from "./deliveries";
import menuSchedules from "./menuSchedules";
import ratings from "./ratings";

setupAxios();

const api = {
  data,
  auth,
  users,
  kitchenRegistrations,
  menuSchedules,
  orders,
  deliveries,
  payments,
  settlements,
  refunds,
  ratings,
};

export default api;
