import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMui,
} from "@mui/material";
import getPropertyValue from "./getPropertyValue";

const Select = ({
  formik,
  label,
  state,
  options,
  disabled = false,
  restOfProps,
}) => {
  const { values, touched, errors, handleChange, handleBlur } = formik;

  const value = getPropertyValue(values, state) || "";
  const isTouched = getPropertyValue(touched, state);
  const error = getPropertyValue(errors, state);
  const showError = Boolean(isTouched && error);

  return (
    <Box sx={{ minWidth: 120, marginBottom: 1 }}>
      <FormControl fullWidth error={showError}>
        <InputLabel size="small" id={`${state}-label`}>
          {label}
        </InputLabel>
        <SelectMui
          labelId={`${state}-label`}
          size="small"
          name={state}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          label={label}
          disabled={disabled}
          {...restOfProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </SelectMui>
        <FormHelperText>{showError ? error : " "}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default Select;
