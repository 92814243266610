import { Typography } from "@mui/material";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ actual, total, text, color, type = "progress" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {type === "progress" ? (
          <CircularProgressbar
            value={(actual / total) * 100}
            text={`${actual} / ${total}`}
            styles={{
              path: { stroke: color },
              text: { fill: color },
            }}
          />
        ) : (
          <Typography sx={{ color }} variant="h5">
            {actual}
          </Typography>
        )}
      </div>
      <Typography
        sx={{ color, marginTop: "5px", textAlign: "center" }}
        variant="caption"
      >
        {text}
      </Typography>
    </div>
  );
};

export default ProgressBar;
