import { useOrderPageContext } from "../../../context/order-page-context";
import TitleWithButton from "../../TitleWithButton";
import DeliverySummary from "./DeliverySummary";

const DeliveryManagement = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      {order.finalStatus !== "pending" && !order.isPaymentExpired && (
        <>
          <TitleWithButton title="Delivery" />
          <DeliverySummary order={order} />
        </>
      )}
    </>
  );
};

export default DeliveryManagement;
