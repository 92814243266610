import { Typography } from "@mui/material";

const NoListFound = ({ text }) => {
  return (
    <Typography variant="body1" align="center" sx={{ my: 4 }}>
      {text}
    </Typography>
  );
};

export default NoListFound;
