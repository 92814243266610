import { Card, CardContent, List, ListItemText } from "@mui/material";
import Timeline from "../../Timeline";

const Reversal = ({ reversal }) => {
  const {
    paymentGateway,
    paymentId,
    amount,
    receipt,
    refundId,
    status,
    timeline,
  } = reversal;

  const list = [
    {
      primary: "Payment Gateway",
      secondary: paymentGateway,
    },
    {
      primary: "Payment ID",
      secondary: paymentId,
    },
    {
      primary: "Amount",
      secondary: `${amount}`,
    },
    {
      primary: "Receipt",
      secondary: receipt,
    },
    {
      primary: "Refund ID",
      secondary: refundId,
    },
    {
      primary: "Status",
      secondary: status,
    },
  ];

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <List>
          {list.map(({ primary, secondary }) => (
            <ListItemText
              key={primary}
              primary={primary}
              secondary={secondary}
            />
          ))}
        </List>
        <Timeline timeline={timeline} withoutCard />
      </CardContent>
    </Card>
  );
};

export default Reversal;
