import { Typography } from "@mui/material";
import Reversal from "./Reversal";

const Reversals = ({ refund }) => {
  const { reversals } = refund;

  if (!reversals.length) return null;

  return (
    <>
      <Typography variant="h6">Reversals</Typography>
      {reversals.map((reversal, index) => (
        <Reversal key={index} reversal={reversal} />
      ))}
    </>
  );
};

export default Reversals;
