import React, { useState } from "react";
import { Button, Card, CardContent, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import PaginatedFilterList from "../components/PaginatedFilterList/PaginatedFilterList";
import ColonText from "../components/ColonText";

import api from "../api";
import formatTimestamp from "../utils/formatTimestamp";

const RefundsPage = () => {
  const navigate = useNavigate();
  const [refunds, setRefunds] = useState([]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Refunds</PageTitle>
        <PaginatedFilterList
          apiResponseKey={"refunds"}
          getListApi={api.refunds.getAllRefunds}
          statusOptions={[
            { value: "pending", label: "Pending" },
            { value: "completed", label: "Completed" },
            { value: "failed", label: "Failed" },
          ]}
          updateList={setRefunds}
          list={refunds}
          setList={setRefunds}
        >
          {refunds.map((refund) => (
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <ColonText first={"Order ID"} second={refund.order} />
                <ColonText
                  first={"Amount"}
                  second={"₹ " + refund.refundAmount}
                />
                <ColonText first={"Status"} second={refund.status} />
                <ColonText
                  first={"Updated at"}
                  second={formatTimestamp(refund.updatedAt)}
                />
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/refunds/${refund._id}`)}
                  endIcon={<ArrowForward />}
                  sx={{ mt: 1 }}
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          ))}
        </PaginatedFilterList>
      </Container>
    </>
  );
};

export default RefundsPage;
