import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import AuthContextProvider from "./context/auth-context";
import AdminAccessCheck from "./hoc/AdminAccessCheck";
import DataContextProvider from "./context/data-context";
import App from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <AdminAccessCheck>
        <DataContextProvider>
          <App />
        </DataContextProvider>
      </AdminAccessCheck>
    </AuthContextProvider>
  </BrowserRouter>
);
