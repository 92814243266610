import { Container } from "@mui/material";

import Header from "../../components/Header/Header";
import Auth from "../../components/Auth/Auth";
import PageTitle from "../../components/PageTitle";

const AuthPage = () => {
  return (
    <>
      <Header hideBackButton />
      <Container>
        <PageTitle>Admin portal</PageTitle>
        <Auth />
      </Container>
    </>
  );
};

export default AuthPage;
