import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import {
  ProcessExpiredPayment,
  PaymentDetails,
  OnlinePayment,
} from "../components/pages/PaymentPage";

import api from "../api";
import handleError from "../utils/handleError";

const PaymentPage = () => {
  const { paymentId } = useParams();
  const [payment, setPayment] = useState(null);

  const fetchPayment = useCallback(async () => {
    try {
      const {
        data: { payment },
      } = await api.payments.getPayment({
        paymentId,
      });

      setPayment(payment);
    } catch (error) {
      handleError(error);
    }
  }, [paymentId]);

  useEffect(() => {
    fetchPayment();
  }, [fetchPayment]);

  if (!payment) return null;

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Payment</PageTitle>
        <ProcessExpiredPayment payment={payment} fetchPayment={fetchPayment} />
        <PaymentDetails payment={payment} />
        <OnlinePayment payment={payment} />
      </Container>
    </>
  );
};

export default PaymentPage;
