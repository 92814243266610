import NoListFound from "../../NoListFound";
import OrderCard from "../../OrderCard";

const Orders = ({ orders }) => {
  return (
    <>
      {orders.length === 0 && <NoListFound text={"No Orders Found"} />}
      {orders.map((order) => (
        <OrderCard key={order._id} order={order} />
      ))}
    </>
  );
};

export default Orders;
