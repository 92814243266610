import { IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchInput = ({ label, value, setValue, searchHandler, sx }) => {
  return (
    <TextField
      size="small"
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <>
            {value && (
              <IconButton
                size="small"
                edge="end"
                color="primary"
                onClick={searchHandler}
              >
                <SearchIcon />
              </IconButton>
            )}
          </>
        ),
      }}
      sx={sx}
    />
  );
};

export default SearchInput;
