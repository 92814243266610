import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import CardTitleWithButton from "../../CardTitleWithButton";
import LabelValueText from "../../LabelValueText";
import { useData } from "../../../context/data-context";
import withDataCheck from "../../../hoc/withDataCheck";

const ConfigurationDisplay = ({ isEditable, configuration }) => {
  const navigate = useNavigate();
  const data = useData();

  let {
    cuisines,
    mealTypes,
    mealTimes,
    periods,
    servingCapacityPerMealTime,
    deliveryDays,
    deliveryRadius,
  } = configuration;

  cuisines = cuisines.map((cuisine) => data.cuisineLabels[cuisine]);
  mealTypes = mealTypes.map((mealType) => data.mealTypeLabels[mealType]);
  mealTimes = mealTimes.map((mealTime) => data.mealTimeLabels[mealTime]);
  periods = periods.map((period) => data.periodLabels[period]);
  deliveryDays = deliveryDays.map((deliveryDay) => data.dayLabels[deliveryDay]);

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <CardTitleWithButton
          title="Configuration"
          btnName={isEditable && "Edit"}
          btnClickHandler={() =>
            navigate("/kitchen-registration/configuration")
          }
        />
        <LabelValueText label="Cuisines" value={cuisines.join(", ")} />
        <LabelValueText label="Meal Types" value={mealTypes.join(", ")} />
        <LabelValueText label="Meal Times" value={mealTimes.join(", ")} />
        <LabelValueText label="Periods" value={periods.join(", ")} />
        <LabelValueText
          label="Serving Capacity Per Meal Time"
          value={servingCapacityPerMealTime}
        />
        <LabelValueText label="Delivery Days" value={deliveryDays.join(", ")} />
        <LabelValueText
          label="Delivery Radius"
          value={`${deliveryRadius / 1000} Km`}
        />
      </CardContent>
    </Card>
  );
};

export default withDataCheck(ConfigurationDisplay);
