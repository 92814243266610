import { Box, Card, CardContent, List, ListItemText } from "@mui/material";

import Timeline from "../../Timeline";

const RefundDetails = ({ refund }) => {
  const {
    order: orderId,
    _id: refundId,
    orderAmount,
    totalDeliveriesCount,
    currency,
    pricePerDelivery,
    refundDeliveriesCount,
    refundAmount,
    refundReason,
    status,
  } = refund;

  const list = [
    {
      primary: "Order ID",
      secondary: orderId,
    },
    {
      primary: "Refund ID",
      secondary: refundId,
    },
    {
      primary: "Order Amount",
      secondary: `${currency} ${orderAmount}`,
    },
    {
      primary: "Total Order Deliveries",
      secondary: totalDeliveriesCount,
    },
    {
      primary: "Price Per Delivery",
      secondary: `${currency} ${pricePerDelivery}`,
    },
    {
      primary: "Total Refund Deliveries",
      secondary: refundDeliveriesCount,
    },
    {
      primary: "Refund Amount",
      secondary: `${currency} ${refundAmount}`,
    },
    {
      primary: "Reason",
      secondary: refundReason,
    },
    {
      primary: "Status",
      secondary: status,
    },
  ];

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <List>
            {list.map(({ primary, secondary }) => (
              <ListItemText
                key={primary}
                primary={primary}
                secondary={secondary}
              />
            ))}
          </List>
          <Timeline timeline={refund.timeline} withoutCard />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RefundDetails;
