const multiplyDecimal = (num1, num2) => Math.round(num1 * num2);

const calculatePrice = ({
  pricing,
  cuisine,
  mealType,
  mealTime,
  period,
  quantity,
  periodToDaysMap,
}) => {
  const cuisineMultiplier =
    pricing.cuisineMultipliers.find((item) => item.cuisine === cuisine)
      .multiplier - 1;

  const mealTypeMultiplier =
    pricing.mealTypeMultipliers.find((item) => item.mealType === mealType)
      .multiplier - 1;

  const mealTimeMultiplier =
    pricing.mealTimeMultipliers.find((item) => item.mealTime === mealTime)
      .multiplier - 1;

  const periodMultiplier =
    pricing.periodMultipliers.find((item) => item.period === period)
      .multiplier - 1;

  const { basePrice } = pricing;
  const cuisinePrice = multiplyDecimal(basePrice, cuisineMultiplier);
  const mealTypePrice = multiplyDecimal(basePrice, mealTypeMultiplier);
  const mealTimePrice = multiplyDecimal(basePrice, mealTimeMultiplier);
  const periodPrice = multiplyDecimal(basePrice, periodMultiplier);

  const pricePerMeal =
    basePrice + cuisinePrice + mealTypePrice + mealTimePrice + periodPrice;

  const totalDays = periodToDaysMap[period];
  const totalDaysPrice = multiplyDecimal(pricePerMeal, totalDays);

  const totalQuantity = quantity;
  const totalQuantityPrice = multiplyDecimal(totalDaysPrice, totalQuantity);

  const finalPrice = totalQuantityPrice;

  return finalPrice;
};

export default calculatePrice;
