import { Typography } from "@mui/material";

const TitleWithButton = ({ title, button }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 0px",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {button && button}
    </div>
  );
};

export default TitleWithButton;
