import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { toast } from "react-toastify";

const FetchLatestStatus = ({ settlement, fetchSettlement }) => {
  if (settlement.status !== "pending") return null;

  const fetchLatestStatus = async () => {
    try {
      await api.settlements.refreshSettlement({
        settlementId: settlement._id,
      });
      await fetchSettlement();
      toast.success("Settlement refreshed successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      fullWidth
      variant="contained"
      color="success"
      sx={{
        mb: 2,
      }}
      onClick={fetchLatestStatus}
    >
      Fetch Latest Status
    </Button>
  );
};

export default FetchLatestStatus;
