import NoListFound from "../../NoListFound";
import PaymentCard from "../../PaymentCard";

const Payments = ({ payments }) => {
  return (
    <>
      {payments.length === 0 && <NoListFound text={"No Payments Found"} />}
      {payments.map((payment) => (
        <PaymentCard key={payment._id} payment={payment} />
      ))}
    </>
  );
};

export default Payments;
