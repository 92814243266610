import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";

const ProcessAllPayments = ({ fetchPayments }) => {
  const processAllPayments = async () => {
    try {
      await api.payments.processAllExpiredPayments();
      await fetchPayments();
      toast.success("All Expired payments processed successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      fullWidth
      variant="contained"
      color="success"
      onClick={processAllPayments}
      endIcon={<CheckIcon />}
    >
      Process All Payments
    </Button>
  );
};

export default ProcessAllPayments;
