const getPropertyValue = (obj, path) => {
  const properties = path.split(".");
  let value = obj;

  for (let prop of properties) {
    value = value[prop];

    if (!value) {
      break;
    }
  }
  return value;
};

export default getPropertyValue;
