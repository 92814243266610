import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SvgIcon from "@mui/material/SvgIcon";
import ListItemText from "@mui/material/ListItemText";

const colorMap = {
  // kitchen registration
  created: "blue",
  applied: "orange",
  approved: "green",
  declined: "red",

  // delivery
  dispatched: "orange",
  cancelled: "red",
  missed: "red",
  completed: "green",

  // transactions
  pending: "orange",
  failed: "red",

  // Payment gateway
  processing: "orange",
  reversed: "red",
  processed: "green",
};

const TimelineDotIcon = ({ color }) => (
  <SvgIcon>
    <circle cx="12" cy="12" r="8" fill={color} />
  </SvgIcon>
);

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return (
    date.toDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
};

const Timeline = ({ timeline, withoutCard }) => {
  const content = (
    <List>
      {timeline.map((item, index) => (
        <ListItem key={index} style={{ padding: 0, alignItems: "flex-start" }}>
          <ListItemIcon style={{ marginTop: "5px" }}>
            <TimelineDotIcon color={colorMap[item.status]} />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography variant="body1">{item.status}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {formatTimestamp(item.timestamp)}
                </Typography>
              </>
            }
            secondary={
              item.notes && (
                <Typography variant="body2">{item.notes}</Typography>
              )
            }
          />
        </ListItem>
      ))}
    </List>
  );

  if (withoutCard) {
    return content;
  }

  return (
    <Card>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

export default Timeline;
