import { Typography } from "@mui/material";

const IconText = ({ icon, text, variant }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", margin: "5px 0px" }}>
      {icon}
      <Typography
        variant={variant || "body2"}
        sx={{
          marginLeft: 1,
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default IconText;
