import { Typography } from "@mui/material";

const ColonText = ({ first, second }) => {
  return (
    <Typography variant="body1" sx={{ margin: "5px 0px" }}>
      <strong>{first} : </strong> {second}
    </Typography>
  );
};

export default ColonText;
