import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import api from "../../../api";
import handleError from "../../../utils/handleError";
import { toast } from "react-toastify";

const ReversalRefund = ({ refund, fetchRefund }) => {
  const [open, setOpen] = useState(false);
  const refundId = refund._id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = async () => {
    try {
      await api.refunds.retryReversalRefund({
        refundId,
      });
      await fetchRefund();
      toast.success("Reversal refund created successfully");
      handleClose();
    } catch (error) {
      handleError(error);
      handleClose();
    }
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        Reversal
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reversal refund</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to create a reversal refund?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitHandler} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReversalRefund;
