import React from "react";
import { useData } from "../context/data-context";

const withDataCheck = (WrappedComponent) => {
  return (props) => {
    const data = useData();

    if (!data) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withDataCheck;
