import { Container, Typography } from "@mui/material";
import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import { useEffect } from "react";
import { useAuth } from "../context/auth-context";
import { useNavigate } from "react-router-dom";

const AccessDeniedPage = () => {
  const navigate = useNavigate();
  const authCtx = useAuth();

  useEffect(() => {
    const { user } = authCtx;
    if (user && user.roles && user.roles.includes("admin")) {
      navigate("/", { replace: true });
    }
  }, [authCtx, navigate]);

  return (
    <>
      <Header hideBackButton />
      <Container>
        <PageTitle>Access Denied</PageTitle>
        <Typography variant="body1" textAlign={"center"} padding={1.5}>
          Only admins can access this portal. Please contact support.
        </Typography>
      </Container>
    </>
  );
};

export default AccessDeniedPage;
