import React, { useState } from "react";
import { Button, Card, CardContent, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import PaginatedFilterList from "../components/PaginatedFilterList/PaginatedFilterList";
import ColonText from "../components/ColonText";

import api from "../api";
import formatTimestamp from "../utils/formatTimestamp";
import PaymentCard from "../components/PaymentCard";

const PaymentsPage = () => {
  const navigate = useNavigate();
  const [payments, setPayments] = useState([]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Payments</PageTitle>
        <PaginatedFilterList
          apiResponseKey={"payments"}
          getListApi={api.payments.getAllPayments}
          statusOptions={[
            { value: "pending", label: "Pending" },
            { value: "completed", label: "Completed" },
            { value: "expired", label: "Expired" },
          ]}
          updateList={setPayments}
          list={payments}
          setList={setPayments}
        >
          {payments.map((payment) => (
            <PaymentCard key={payment._id} payment={payment} />
          ))}
        </PaginatedFilterList>
      </Container>
    </>
  );
};

export default PaymentsPage;
