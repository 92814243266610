import { Container } from "@mui/material";
import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import {
  ProcessAllOrders,
  Orders,
} from "../components/pages/UnprocessedExpiredOrdersPage";
import { useEffect, useState } from "react";
import api from "../api";
import handleError from "../utils/handleError";

const UnprocessedExpiredOrdersPage = () => {
  const [orders, setOrders] = useState([]);

  const fetchOrders = async () => {
    try {
      const {
        data: { orders },
      } = await api.orders.getAllUnprocessedExpiredOrders();
      setOrders(orders);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Unprocessed Expired Orders</PageTitle>
        {orders.length > 0 && <ProcessAllOrders fetchOrders={fetchOrders} />}
        <Orders orders={orders} />
      </Container>
    </>
  );
};

export default UnprocessedExpiredOrdersPage;
