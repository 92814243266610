import { Box, Card, CardContent, List, ListItemText } from "@mui/material";

import Timeline from "../../Timeline";

const PaymentDetails = ({ payment }) => {
  const {
    order: orderId,
    _id: paymentId,
    amount,
    currency,
    finalStatus,
    timeline,
  } = payment;

  const list = [
    {
      primary: "Order ID",
      secondary: orderId,
    },
    {
      primary: "Payment ID",
      secondary: paymentId,
    },
    {
      primary: "Amount",
      secondary: `${currency} ${amount}`,
    },
    {
      primary: "Status",
      secondary: finalStatus,
    },
  ];

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <List>
            {list.map(({ primary, secondary }) => (
              <ListItemText
                key={primary}
                primary={primary}
                secondary={secondary}
              />
            ))}
          </List>
          <Timeline timeline={timeline} withoutCard />
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentDetails;
