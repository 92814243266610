import { Button } from "@mui/material";
import { useOrderPageContext } from "../../../context/order-page-context";
import TitleWithButton from "../../TitleWithButton";
import PaymentSummary from "./PaymentSummary";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const navigate = useNavigate();
  const { order } = useOrderPageContext();

  return (
    <>
      <TitleWithButton
        title={"Payment"}
        button={
          <Button
            size="small"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() => navigate(`/payments/${order.payment._id}`)}
          >
            View Payment
          </Button>
        }
      />
      <PaymentSummary payment={order.payment} />
    </>
  );
};

export default Payment;
