import { Typography } from "@mui/material";
import Payout from "./Payout";

const Payouts = ({ refund }) => {
  const { payouts } = refund;

  if (!payouts.length) return null;

  return (
    <>
      <Typography variant="h6">Payouts</Typography>
      {payouts.map((payout, index) => (
        <Payout key={index} payout={payout} />
      ))}
    </>
  );
};

export default Payouts;
