import axios from "axios";
import getAll from "../utils/getAll";

let base = "/settlements/";

const getAllSettlements = getAll(base);

const getSettlement = async ({ settlementId }) =>
  (await axios.get(base + settlementId + "?role=admin")).data;

const retrySettlement = async ({ settlementId, accountNumber, ifsc }) =>
  (
    await axios.post(base + settlementId + "/retry?role=admin", {
      accountNumber,
      ifsc,
    })
  ).data;

const refreshSettlement = async ({ settlementId }) =>
  (await axios.post(base + settlementId + "/refresh?role=admin")).data;

const settlements = {
  getAllSettlements,
  getSettlement,
  retrySettlement,
  refreshSettlement,
};

export default settlements;
