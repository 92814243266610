import { Card, CardContent, Typography } from "@mui/material";
import { useDeliveryPageCtx } from "../../../context/delivery-page-context";

const MenuCard = () => {
  const {
    deliveryStatus,
    menu: { title, description },
  } = useDeliveryPageCtx();

  if (deliveryStatus === "invalid") return null;

  return (
    <Card sx={{ margin: "15px 0px" }}>
      <CardContent>
        <Typography variant="h6">Menu</Typography>
        <Typography>
          <strong>{title}</strong> : {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MenuCard;
