import { Card, CardContent, List, ListItemText } from "@mui/material";
import Timeline from "../../Timeline";

const Payout = ({ payout }) => {
  const {
    payoutGateway,
    accountNumber,
    ifsc,
    amount,
    payoutId,
    referenceId,
    status,
    timeline,
  } = payout;

  const list = [
    {
      primary: "Payout Gateway",
      secondary: payoutGateway,
    },
    {
      primary: "Payout ID",
      secondary: payoutId,
    },
    {
      primary: "Bank Account Number",
      secondary: accountNumber,
    },
    {
      primary: "Bank IFSC",
      secondary: ifsc,
    },
    {
      primary: "Amount",
      secondary: `₹${amount}`,
    },
    {
      primary: "Reference ID",
      secondary: referenceId,
    },
    {
      primary: "Status",
      secondary: status,
    },
  ];

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <List>
          {list.map(({ primary, secondary }) => (
            <ListItemText
              key={primary}
              primary={primary}
              secondary={secondary}
            />
          ))}
        </List>
        <Timeline timeline={timeline} withoutCard />
      </CardContent>
    </Card>
  );
};

export default Payout;
