import { Card, CardContent } from "@mui/material";

import ColonText from "../../ColonText";

import formatTimestamp from "../../../utils/formatTimestamp";
import { useData } from "../../../context/data-context";
import { useOrderPageContext } from "../../../context/order-page-context";

const OrderSummaryCard = () => {
  const data = useData();
  const { order } = useOrderPageContext();

  const {
    pincode,
    kitchen,
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
    price,
    createdAt,
    startDate,
    endDate,
    finalStatus,
    expiryDetails,
    timeline,
    isPaymentExpired,
  } = order;

  return (
    <Card>
      <CardContent>
        <ColonText first={"Pincode"} second={pincode} />
        <ColonText first={"Kitchen"} second={kitchen.name} />
        <ColonText first={"Cuisine"} second={data.cuisineLabels[cuisine]} />
        <ColonText first={"Meal Type"} second={data.mealTypeLabels[mealType]} />
        <ColonText first={"Meal Time"} second={data.mealTimeLabels[mealTime]} />
        <ColonText first={"Quantity"} second={quantity} />
        <ColonText first={"Price"} second={"₹" + price + " (incl. of taxes)"} />
        <ColonText first={"Period"} second={data.periodToDaysMap[period]} />
        <ColonText
          first={"Start Date"}
          second={new Date(startDate).toDateString()}
        />
        {finalStatus === "active" && (
          <ColonText
            first={"End Date"}
            second={new Date(endDate).toDateString()}
          />
        )}
        <ColonText
          first={"Order Placed on"}
          second={formatTimestamp(createdAt)}
        />
        {finalStatus === "expired" && (
          <ColonText
            first={"Expired on"}
            second={
              isPaymentExpired
                ? formatTimestamp(expiryDetails.timestamp)
                : new Date(expiryDetails.timestamp).toDateString()
            }
          />
        )}
        {finalStatus === "cancelled" && (
          <ColonText
            first={"Cancelled on"}
            second={formatTimestamp(timeline[timeline.length - 1].timestamp)}
          />
        )}
        {finalStatus === "completed" && (
          <ColonText
            first={"Completed on"}
            second={new Date(
              timeline[timeline.length - 1].timestamp
            ).toDateString()}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default OrderSummaryCard;
