import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { useOrderPageContext } from "../../../context/order-page-context";
import { toast } from "react-toastify";

const ProcessExpiredOrder = () => {
  const {
    order: { _id: orderId },
    fetchOrder,
  } = useOrderPageContext();

  const processExpiredOrder = async () => {
    try {
      await api.orders.processExpiredOrder({
        orderId,
      });
      toast.success("Order processed successfully");
      fetchOrder();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="success"
      onClick={processExpiredOrder}
    >
      Process Expired Order
    </Button>
  );
};

export default ProcessExpiredOrder;
