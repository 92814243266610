import { Button } from "@mui/material";
import { useOrderPageContext } from "../../../context/order-page-context";
import TitleWithButton from "../../TitleWithButton";
import SettlementSummary from "./SettlementSummary";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Settlement = () => {
  const navigate = useNavigate();
  const { order } = useOrderPageContext();

  if (!order.settlement) return null;

  return (
    <>
      <TitleWithButton
        title={"Settlement"}
        button={
          <Button
            size="small"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() => navigate(`/settlements/${order.settlement._id}`)}
          >
            View Settlement
          </Button>
        }
      />
      <SettlementSummary settlement={order.settlement} />
    </>
  );
};

export default Settlement;
