import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import {
  RetrySettlement,
  SettlementDetails,
  Payouts,
  FetchLatestStatus,
} from "../components/pages/SettlementPage";

import api from "../api";
import handleError from "../utils/handleError";

const SettlementPage = () => {
  const { settlementId } = useParams();
  const [settlement, setSettlement] = useState(null);

  const fetchSettlement = useCallback(async () => {
    try {
      const {
        data: { settlement },
      } = await api.settlements.getSettlement({
        settlementId,
      });

      setSettlement(settlement);
    } catch (error) {
      handleError(error);
    }
  }, [settlementId]);

  useEffect(() => {
    fetchSettlement();
  }, [fetchSettlement]);

  if (!settlement) return null;

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Settlement</PageTitle>
        <RetrySettlement
          settlement={settlement}
          fetchSettlement={fetchSettlement}
        />
        <FetchLatestStatus
          settlement={settlement}
          fetchSettlement={fetchSettlement}
        />
        <SettlementDetails settlement={settlement} />
        <Payouts settlement={settlement} />
      </Container>
    </>
  );
};

export default SettlementPage;
