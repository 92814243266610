import { Box, Typography } from "@mui/material";
import SameBankDetails from "./SameBankDetails";
import NewBankDetails from "./NewBankDetails";

const RetrySettlement = ({ settlement, fetchSettlement }) => {
  if (settlement.status !== "failed") return null;
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Retry Settlement
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
        }}
      >
        <SameBankDetails
          settlement={settlement}
          fetchSettlement={fetchSettlement}
        />
        <NewBankDetails
          settlement={settlement}
          fetchSettlement={fetchSettlement}
        />
      </Box>
    </Box>
  );
};

export default RetrySettlement;
