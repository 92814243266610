import { Container } from "@mui/material";

import Header from "../../components/Header/Header";
import PageTitle from "../../components/PageTitle";
import LoginWithOtp from "../../components/Auth/LoginWithOtp";

const LoginWithOtpPage = () => {
  return (
    <>
      <Header />
      <Container>
        <PageTitle>Login with OTP</PageTitle>
        <LoginWithOtp />
      </Container>
    </>
  );
};

export default LoginWithOtpPage;
