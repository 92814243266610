import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { toast } from "react-toastify";

const ProcessExpiredPayment = ({ payment, fetchPayment }) => {
  if (!(payment.status === "pending" && payment.finalStatus === "expired"))
    return null;

  const processExpiredPayment = async () => {
    try {
      await api.payments.processExpiredPayment({ paymentId: payment._id });
      await fetchPayment();
      toast.success("Expired Payment processed successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      fullWidth
      size="small"
      variant="contained"
      color="warning"
      sx={{ mb: 2 }}
      onClick={processExpiredPayment}
    >
      Process Expired Payment
    </Button>
  );
};

export default ProcessExpiredPayment;
