import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import ColorLegend from "./ColorLegend";

import { deliveryStatusToColorMapping } from "../../../../utils/mappings";
import styles from "./DeliveryCalendar.module.css";

const DeliveryCalendar = ({ order }) => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const {
    startDate,
    deliverableDates = [],
    nonDeliverableDates = [],
    completedDates = [],
    skippedDates = [],
    missedDates = [],
    cutoffDate,
  } = order;

  const handleDateClick = (date) => {
    const isoDate = date.toISOString();
    if (date >= new Date(startDate) && date <= new Date(cutoffDate)) {
      navigate(`/orders/${orderId}/delivery/${isoDate}`);
    }
  };

  return (
    <div style={{ margin: "15px 0px" }}>
      <Calendar
        minDate={new Date(startDate)}
        maxDate={new Date(cutoffDate)}
        tileClassName={({ date }) => {
          const isoDate = date.toISOString();
          if (new Date(isoDate) <= new Date(cutoffDate)) {
            if (completedDates.includes(isoDate)) {
              return styles["completed-date"];
            }
            if (skippedDates.includes(isoDate)) {
              return styles["skipped-date"];
            }
            if (missedDates.includes(isoDate)) {
              return styles["missed-date"];
            }
            if (deliverableDates.includes(isoDate)) {
              return styles["deliverable-date"];
            }
            if (nonDeliverableDates.includes(isoDate)) {
              return styles["non-deliverable-date"];
            }
          }
          return null;
        }}
        onClickDay={handleDateClick}
      />
      <ColorLegend
        legendItems={[
          {
            color: deliveryStatusToColorMapping.completed,
            description: "Completed",
          },
          {
            color: deliveryStatusToColorMapping.skipped,
            description: "Skipped by you",
          },
          {
            color: deliveryStatusToColorMapping.missed,
            description: "Missed by kitchen",
          },
          {
            color: deliveryStatusToColorMapping.deliverable,
            description: "Deliverable",
          },
          {
            color: deliveryStatusToColorMapping.nonDeliverable,
            description: "Non Deliverable",
          },
        ]}
      />
    </div>
  );
};

export default DeliveryCalendar;
