import axios from "axios";
import getAll from "../utils/getAll";

let base = "/orders/";

const getAllOrders = getAll(base);

const getAllUnprocessedExpiredOrders = async () =>
  (await axios.get(base + "expired/unprocessed")).data;

const get = async ({ orderId }) =>
  (await axios.get(base + orderId + "?role=admin")).data;

const processExpiredOrder = async ({ orderId }) =>
  (await axios.post(base + orderId + "/processExpiredOrder?role=admin")).data;

const processAllExpiredOrders = async () =>
  (await axios.post(base + "processAllExpiredOrders")).data;

const orders = {
  getAll: getAllOrders,
  getAllUnprocessedExpiredOrders,
  get,
  processExpiredOrder,
  processAllExpiredOrders,
};

export default orders;
