const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return (
    date.toDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
};

export default formatTimestamp;
