import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import GoogleSignin from "./GoogleSignin";
import Signup from "./Signup";
import Login from "./Login";

const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useState("signup");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryMode = queryParams.get("mode");

    if (queryMode === "login" || queryMode === "signup") {
      setMode(queryMode);
    } else {
      queryParams.set("mode", "signup");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location, navigate]);

  const handleToggle = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("mode", newMode);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          onChange={handleToggle}
        >
          <ToggleButton value="signup" size="small">
            Sign Up
          </ToggleButton>
          <ToggleButton value="login" size="small">
            Login
          </ToggleButton>
        </ToggleButtonGroup>
        <GoogleSignin />
      </div>
      {mode === "signup" && (
        <Signup
          loginClickHandler={() => {
            setMode("login");
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("mode", "login");
            navigate(`${location.pathname}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
        />
      )}
      {mode === "login" && (
        <Login
          signupClickHandler={() => {
            setMode("signup");
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("mode", "signup");
            navigate(`${location.pathname}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
        />
      )}
    </div>
  );
};

export default Auth;
