import React, { useState } from "react";
import { ArrowUpward } from "@mui/icons-material";
import { Button, Card, CardContent } from "@mui/material";

import LabelValueText from "../../LabelValueText";

import MapLocationViewDrawer from "../../MapLocationViewDrawer";
import CardTitleWithButton from "../../CardTitleWithButton";

const AddressDisplay = ({ isEditable, address }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Card style={{ margin: "20px 0px" }}>
        <CardContent>
          <CardTitleWithButton title="Address" />
          <LabelValueText label="Building" value={address.building} />
          {address.area && <LabelValueText label="Area" value={address.area} />}
          {address.landmark && (
            <LabelValueText label="Landmark" value={address.landmark} />
          )}
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => setIsOpen(true)}
            endIcon={<ArrowUpward />}
            style={{ marginTop: "10px" }}
          >
            View location on Map
          </Button>
        </CardContent>
      </Card>
      <MapLocationViewDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        latitude={address.location.coordinates[1]}
        longitude={address.location.coordinates[0]}
      />
    </>
  );
};

export default AddressDisplay;
