import axios from "axios";

let base = "/deliveries/";

const get = async ({ orderId, date }) =>
  (await axios.get(base + "delivery?role=admin", { params: { orderId, date } }))
    .data;

const deliveries = {
  get,
};

export default deliveries;
