import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import { useEffect } from "react";

const AdminAccessCheck = ({ children }) => {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      if (user && user.roles && !user.roles.includes("admin")) {
        navigate("/access-denied");
      }
    }
  }, [isLoggedIn, user, navigate]);

  return children;
};

export default AdminAccessCheck;
