import { Button } from "@mui/material";
import { useOrderPageContext } from "../../../context/order-page-context";
import TitleWithButton from "../../TitleWithButton";
import RefundSummary from "./RefundSummary";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Refund = () => {
  const navigate = useNavigate();
  const { order } = useOrderPageContext();

  if (!order.refund) return null;

  return (
    <>
      <TitleWithButton
        title={"Refund"}
        button={
          <Button
            size="small"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() => navigate(`/refunds/${order.refund._id}`)}
          >
            View Refund
          </Button>
        }
      />
      <RefundSummary refund={order.refund} />
    </>
  );
};

export default Refund;
