import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import List from "../../List";
import api from "../../../api";
import handleError from "../../../utils/handleError";
import { toast } from "react-toastify";

const SameBankDetails = ({ settlement, fetchSettlement }) => {
  const [open, setOpen] = useState(false);

  const {
    _id: settlementId,
    kitchen: { bankDetails },
  } = settlement;

  const { accountNumber, ifscCode: ifsc } = bankDetails;

  const list = [
    {
      primary: "Bank Account Number",
      secondary: accountNumber,
    },
    {
      primary: "Bank IFSC",
      secondary: ifsc,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = async () => {
    try {
      await api.settlements.retrySettlement({
        settlementId,
        accountNumber,
        ifsc,
      });
      await fetchSettlement();
      toast.success("Payout created successfully");
      handleClose();
    } catch (error) {
      handleError(error);
      handleClose();
    }
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        Same bank details
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create payout</DialogTitle>
        <DialogContent>
          <List list={list} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitHandler} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SameBankDetails;
