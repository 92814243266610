import { createContext, useContext, useEffect, useState } from "react";

import api from "../api";
import handleError from "../utils/handleError";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

const DataContextProvider = ({ children }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.data.getData();
        setData(res.data.data);
      } catch (err) {
        handleError(err);
      }
    };

    fetchData();
  }, []);

  const contextValue = data;

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export default DataContextProvider;
