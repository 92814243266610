import { Menu, MenuItem, Backdrop, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import KitchenIcon from "@mui/icons-material/Kitchen";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PaymentIcon from "@mui/icons-material/Payment";
import RestoreIcon from "@mui/icons-material/Restore";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import { useAuth } from "../context/auth-context";
import { useNavigate } from "react-router-dom";

const PopupMenu = ({ anchorEl, handleMenuClose }) => {
  const navigate = useNavigate();
  const authCtx = useAuth();
  const isOpen = Boolean(anchorEl);

  const list = [
    {
      name: "Kitchen Registrations",
      icon: <KitchenIcon />,
      onClick: () => navigate("/kitchen-registrations"),
    },
    {
      name: "Orders",
      icon: <ListAltIcon />,
      onClick: () => navigate("/orders"),
    },
    {
      name: "Unprocessed Expired Orders",
      icon: <PendingActionsIcon />,
      onClick: () => navigate("/orders/expired/unprocessed"),
    },
    {
      name: "Payments",
      icon: <PaymentIcon />,
      onClick: () => navigate("/payments"),
    },
    {
      name: "Unprocessed Expired Payments",
      icon: <PendingActionsIcon />,
      onClick: () => navigate("/payments/expired/unprocessed"),
    },
    {
      name: "Refunds",
      icon: <RestoreIcon />,
      onClick: () => navigate("/refunds"),
    },
    {
      name: "Settlements",
      icon: <CreditScoreIcon />,
      onClick: () => navigate("/settlements"),
    },
    {
      name: "Logout",
      icon: <LogoutIcon />,
      onClick: () => {
        authCtx.logout();
        toast.success("Logged out successfully!");
        handleMenuClose();
      },
    },
  ];

  return (
    <>
      <Backdrop open={isOpen} onClick={handleMenuClose} sx={{ zIndex: 2 }} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {list.map(({ name, icon, onClick }) => (
          <MenuItem key={name} onClick={onClick}>
            {icon}
            <Typography sx={{ ml: 1 }}>{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PopupMenu;
