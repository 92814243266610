import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import Header from "../components/Header/Header";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const list = [
    {
      title: "Kitchen Registrations",
      path: "/kitchen-registrations",
    },
    {
      title: "Orders",
      path: "/orders",
    },
    {
      title: "Unprocessed Expired Orders",
      path: "/orders/expired/unprocessed",
    },
    {
      title: "Payments",
      path: "/payments",
    },
    {
      title: "Unprocessed Expired Payments",
      path: "/payments/expired/unprocessed",
    },
    {
      title: "Refunds",
      path: "/refunds",
    },
    {
      title: "Settlements",
      path: "/settlements",
    },
  ];

  return (
    <>
      <Header hideBackButton />
      <Container sx={{ mt: 4, mb: 4 }}>
        <Card>
          <CardContent>
            {list.map((item, index) => (
              <Button
                size="small"
                variant="contained"
                fullWidth
                onClick={() => navigate(item.path)}
                endIcon={<ArrowForward />}
                sx={{ mb: 3 }}
              >
                {item.title}
              </Button>
            ))}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default HomePage;
