import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import FormComponents from "../FormComponents/FormComponents";
import OtpVerificationDrawer from "../OtpVerificationDrawer";

import api from "../../api";
import { useAuth } from "../../context/auth-context";
import handleError from "../../utils/handleError";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const LoginWithOtp = () => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const triggerOtp = async (values) => {
    try {
      await api.auth.triggerLoginOtp({
        email: values.email,
      });
      setIsDrawerOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const verifyOtp = async (otp) => {
    try {
      const res = await api.auth.verifyLoginOtp({
        email: formik.values.email,
        otp,
      });
      authCtx.login({ token: res.token, user: res.data.user });
      toast.success("Logged in successfully.");
      navigate("/", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: triggerOtp,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormComponents
          formik={formik}
          data={[
            {
              type: "input",
              label: "Email",
              state: "email",
              value: "",
              inputType: "email",
            },
          ]}
        />
        <Button fullWidth variant="contained" type="submit">
          Login with OTP
        </Button>
      </form>
      <OtpVerificationDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        submitHandler={verifyOtp}
        description="Please enter the 6 digit code sent to your email address to login. valid for 10 minutes."
      />
    </>
  );
};

export default LoginWithOtp;
