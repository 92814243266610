import calculatePrice from "./calculatePrice";

const generatePricingCategories = ({
  data,
  cuisines,
  mealTypes,
  mealTimes,
  periods,
  pricing,
}) => {
  const cuisineOrderPreference = data.cuisines;
  const mealTypeOrderPreference = data.mealTypes;
  const mealTimeOrderPreference = data.mealTimes;
  const periodOrderPreference = data.periods;

  const sortedCuisines = [];
  const sortedMealTypes = [];
  const sortedMealTimes = [];
  const sortedPeriods = [];

  cuisineOrderPreference.forEach((cuisine) => {
    if (cuisines.includes(cuisine)) {
      sortedCuisines.push(cuisine);
    }
  });

  mealTypeOrderPreference.forEach((mealType) => {
    if (mealTypes.includes(mealType)) {
      sortedMealTypes.push(mealType);
    }
  });

  mealTimeOrderPreference.forEach((mealTime) => {
    if (mealTimes.includes(mealTime)) {
      sortedMealTimes.push(mealTime);
    }
  });

  periodOrderPreference.forEach((period) => {
    if (periods.includes(period)) {
      sortedPeriods.push(period);
    }
  });

  const categories = [];

  sortedCuisines.forEach((cuisine) => {
    sortedMealTypes.forEach((mealType) => {
      sortedMealTimes.forEach((mealTime) => {
        sortedPeriods.forEach((period) => {
          const price = calculatePrice({
            cuisine,
            mealType,
            mealTime,
            period,
            quantity: 1,
            pricing,
            periodToDaysMap: data.periodToDaysMap,
          });
          categories.push({ mealTime, cuisine, mealType, period, price });
        });
      });
    });
  });

  return categories;
};

export default generatePricingCategories;
