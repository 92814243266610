import axios from "axios";

let base = "/auth/";

const auth = {
  // Signup
  triggerSignupOtp: async (data) =>
    (await axios.post(base + "signup/triggerOtp", data)).data,
  verifySignupOtp: async (data) =>
    (await axios.post(base + "signup/verifyOtp", data)).data,

  // Google Signin
  googleSignin: async (data) =>
    (await axios.post(base + "googleSignin", data)).data,

  // Login
  login: async (data) => await (await axios.post(base + "login", data)).data,
  triggerLoginOtp: async (data) =>
    (await axios.post(base + "login/triggerOtp", data)).data,
  verifyLoginOtp: async (data) =>
    (await axios.post(base + "login/verifyOtp", data)).data,

  // Reset password
  triggerResetPasswordOtp: async (data) =>
    (await axios.post(base + "resetPassword/triggerOtp", data)).data,
  verifyResetPasswordOtp: async (data) =>
    (await axios.post(base + "resetPassword/verifyOtp", data)).data,
};

export default auth;
