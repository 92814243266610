import { APIProvider } from "@vis.gl/react-google-maps";

import { ToastContainer } from "react-toastify";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

import "./App.css";
import Router from "./routes/Router";
import useGoogleMapsAPI from "./hooks/useGoogleMapsApi";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fce44d",
    },
  },
});

const App = () => {
  useGoogleMapsAPI("initMap");
  return (
    <ThemeProvider theme={theme}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
        <ToastContainer />
        <Router />
      </APIProvider>
    </ThemeProvider>
  );
};

export default App;
