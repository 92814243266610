import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import getPropertyValue from "./getPropertyValue";

const MultiSelect = ({ formik, state, label, options, disabled = false }) => {
  const { values, touched, errors, handleBlur, setFieldValue } = formik;

  const value = getPropertyValue(values, state) || [];
  const isTouched = getPropertyValue(touched, state);
  const error = getPropertyValue(errors, state);
  const showError = Boolean(isTouched && error);

  const handleChange = (event) => {
    setFieldValue(state, event.target.value);
  };

  return (
    <Box sx={{ marginBottom: 1 }}>
      <FormControl fullWidth error={showError}>
        <InputLabel id={`${state}-label`}>{label}</InputLabel>
        <Select
          labelId={`${state}-label`}
          multiple
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={options.find((option) => option.value === value).label}
                />
              ))}
            </Box>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{showError ? error : " "}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default MultiSelect;
