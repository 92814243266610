import { Box, Typography } from "@mui/material";
import ReversalRefund from "./ReversalRefund";
import PayoutRefund from "./PayoutRefund";

const RetryRefund = ({ refund, fetchRefund }) => {
  if (refund.status !== "failed") return null;
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Retry Refund
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
        }}
      >
        <ReversalRefund refund={refund} fetchRefund={fetchRefund} />
        <PayoutRefund refund={refund} fetchRefund={fetchRefund} />
      </Box>
    </Box>
  );
};

export default RetryRefund;
