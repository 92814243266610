import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { toast } from "react-toastify";

const FetchLatestStatus = ({ refund, fetchRefund }) => {
  if (refund.status !== "pending") return null;

  const fetchLatestStatus = async () => {
    try {
      await api.refunds.refreshRefund({
        refundId: refund._id,
      });
      await fetchRefund();
      toast.success("Refund refreshed successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      fullWidth
      variant="contained"
      color="success"
      sx={{
        mb: 2,
      }}
      onClick={fetchLatestStatus}
    >
      Fetch Latest Status
    </Button>
  );
};

export default FetchLatestStatus;
