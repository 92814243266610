import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Alert,
  Box,
} from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import IconText from "./IconText";

import {
  orderStatusToMuiColor,
  refundStatusToColor,
  expiryReasonMapping,
} from "../utils/mappings";
import formatTimestamp from "../utils/formatTimestamp";
import { useData } from "../context/data-context";

const OrderCard = ({ order }) => {
  const data = useData();
  const navigate = useNavigate();

  return (
    <Card sx={{ margin: "16px 0" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px",
          }}
        >
          <Typography variant="h6">{order.kitchen.name}</Typography>
          <Chip
            size="small"
            label={order.finalStatus.toUpperCase()}
            color={orderStatusToMuiColor[order.finalStatus]}
          />
        </Box>
        {order.finalStatus === "pending" && (
          <Alert severity="warning" sx={{ marginBottom: "10px" }}>
            {`Please revise the payment before ${formatTimestamp(
              order.payment.expiry
            )} to activate the order`}
          </Alert>
        )}
        {order.finalStatus === "expired" && (
          <Alert severity="error" sx={{ marginBottom: "10px" }}>
            {"Expiry reason: " +
              expiryReasonMapping[order.expiryDetails.reason]}
          </Alert>
        )}
        <IconText
          icon={<RestaurantMenuIcon />}
          text={`${data.cuisineLabels[order.cuisine]} ${
            data.mealTypeLabels[order.mealType]
          } ${data.mealTimeLabels[order.mealTime]} (${order.quantity})`}
        />
        <IconText
          icon={<ScheduleIcon />}
          text={`${data.periodLabels[order.period]} (${
            data.periodToDaysMap[order.period]
          })`}
        />
        <IconText icon={<CurrencyRupeeIcon />} text={`${order.price}`} />
        <IconText
          icon={<LocationOnIcon />}
          text={`${order.address.fullName} - ${order.address.district} ${order.pincode}`}
        />
        <Typography
          fontSize={12}
          color={"textSecondary"}
          textAlign={"center"}
          sx={{ marginTop: "10px" }}
        >
          Order placed on - {formatTimestamp(order.createdAt)}
        </Typography>
        {order.refund && (
          <Typography
            fontSize={12}
            color={refundStatusToColor[order.refund.status]}
            textAlign={"center"}
          >
            Refund status - {order.refund.status}
          </Typography>
        )}
        <Button
          fullWidth
          size="small"
          variant="contained"
          color="primary"
          sx={{ marginTop: "12px" }}
          onClick={() => navigate(`/orders/${order._id}`)}
          endIcon={<ArrowForwardIcon />}
        >
          View Order Details
        </Button>
      </CardContent>
    </Card>
  );
};

export default OrderCard;
