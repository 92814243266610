import axios from "axios";
import getAll from "../utils/getAll";

let base = "/refunds/";

const getAllRefunds = getAll(base);

const getRefund = async ({ refundId }) =>
  (await axios.get(base + refundId)).data;

const retryReversalRefund = async ({ refundId }) =>
  (await axios.post(base + refundId + "/retry/reversal")).data;

const retryPayoutRefund = async ({ refundId, accountNumber, ifsc }) =>
  (
    await axios.post(base + refundId + "/retry/payout", {
      accountNumber,
      ifsc,
    })
  ).data;

const refreshRefund = async ({ refundId }) =>
  (await axios.post(base + refundId + "/refresh")).data;

const refunds = {
  getAllRefunds,
  getRefund,
  retryReversalRefund,
  retryPayoutRefund,
  refreshRefund,
};

export default refunds;
