import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import {
  ApproveButton,
  DeclineButton,
  BasicDetailsDisplay,
  AddressDisplay,
  ConfigurationDisplay,
  PricingDisplay,
  BankDetailsDisplay,
  LegalDetailsDisplay,
} from "../components/pages/KitchenRegistrationPage";
import Timeline from "../components/Timeline";

import api from "../api";
import handleError from "../utils/handleError";

const KitchenRegistrationPage = () => {
  const params = useParams();
  const [kitchenRegistration, setKitchenRegistration] = useState({});

  const getKitchenRegistration = useCallback(async () => {
    try {
      const res = await api.kitchenRegistrations.getKitchenRegistrationById(
        params.id
      );
      const { kitchenRegistration } = res.data;
      setKitchenRegistration(kitchenRegistration);
    } catch (error) {
      handleError(error);
    }
  }, [params.id]);

  useEffect(() => {
    getKitchenRegistration();
  }, [getKitchenRegistration]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>{kitchenRegistration.name}</PageTitle>
        {kitchenRegistration.status === "applied" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0px",
            }}
          >
            <ApproveButton fetchKitchenRegistration={getKitchenRegistration} />
            <DeclineButton fetchKitchenRegistration={getKitchenRegistration} />
          </div>
        )}
        {kitchenRegistration.timeline && (
          <Timeline timeline={kitchenRegistration.timeline} />
        )}
        {kitchenRegistration.ownerDetails && (
          <BasicDetailsDisplay
            kitchenName={kitchenRegistration.name}
            kitchenTagline={kitchenRegistration.tagline}
            ownerName={kitchenRegistration.ownerDetails.name}
            ownerPhoneNumber={
              kitchenRegistration.ownerDetails.mobileDetails.mobile
            }
          />
        )}
        {kitchenRegistration.address && (
          <AddressDisplay address={kitchenRegistration.address} />
        )}
        {kitchenRegistration.configuration && (
          <ConfigurationDisplay
            configuration={kitchenRegistration.configuration}
          />
        )}
        {kitchenRegistration.configuration && kitchenRegistration.pricing && (
          <PricingDisplay
            configuration={kitchenRegistration.configuration}
            pricing={kitchenRegistration.pricing}
          />
        )}
        {kitchenRegistration.bankDetails && (
          <BankDetailsDisplay bankDetails={kitchenRegistration.bankDetails} />
        )}
        {kitchenRegistration.legalDetails && (
          <LegalDetailsDisplay
            legalDetails={kitchenRegistration.legalDetails}
          />
        )}
      </Container>
    </>
  );
};

export default KitchenRegistrationPage;
