import { Button, Card, CardContent } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import ColonText from "./ColonText";

import formatTimestamp from "../utils/formatTimestamp";

const PaymentCard = ({ payment }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <ColonText first={"Order ID"} second={payment.order} />
        <ColonText first={"Amount"} second={"₹ " + payment.amount} />
        <ColonText first={"Status"} second={payment.finalStatus} />
        <ColonText
          first={"Updated at"}
          second={formatTimestamp(payment.updatedAt)}
        />
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={() => navigate(`/payments/${payment._id}`)}
          endIcon={<ArrowForward />}
          sx={{ mt: 1 }}
        >
          View Details
        </Button>
      </CardContent>
    </Card>
  );
};

export default PaymentCard;
