import { Button, Typography } from "@mui/material";

const CardTitleWithButton = ({ title, btnName, btnClickHandler }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {btnName && (
        <Button size="small" variant="contained" onClick={btnClickHandler}>
          {btnName}
        </Button>
      )}
    </div>
  );
};

export default CardTitleWithButton;
