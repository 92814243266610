import { useState } from "react";
import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import PaginatedFilterList from "../components/PaginatedFilterList/PaginatedFilterList";
import OrderCard from "../components/OrderCard";

import api from "../api";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Orders</PageTitle>
        <PaginatedFilterList
          apiResponseKey={"orders"}
          getListApi={api.orders.getAll}
          statusOptions={[
            { value: "pending", label: "Pending" },
            { value: "active", label: "Active" },
            { value: "cancelled", label: "Cancelled" },
            { value: "completed", label: "Completed" },
            { value: "expired", label: "Expired" },
          ]}
          updateList={setOrders}
          list={orders}
          setList={setOrders}
        >
          {orders.map((order) => (
            <OrderCard key={order._id} order={order} />
          ))}
        </PaginatedFilterList>
      </Container>
    </>
  );
};

export default OrdersPage;
