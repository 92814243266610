import React from "react";
import { Alert, Card, CardContent } from "@mui/material";

import ColonText from "../../ColonText";
import formatTimestamp from "../../../utils/formatTimestamp";

const SettlementSummary = ({ settlement }) => {
  const { settlementAmount, status } = settlement;

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <ColonText first={"Amount"} second={"₹" + settlementAmount} />
        <ColonText first={"Status"} second={status} />
        {settlement.status === "completed" && (
          <ColonText
            first={"Settlement completed on"}
            second={formatTimestamp(
              settlement.timeline[settlement.timeline.length - 1].timestamp
            )}
          />
        )}
        {settlement.status === "pending" && (
          <Alert severity="info">
            Your settlement is currently being processed. Thank you for your
            patience.
          </Alert>
        )}
        {settlement.status === "failed" && (
          <Alert severity="warning">
            We encountered an issue processing your settlement. Our team is
            actively working on resolving it. If the issue persists, please
            contact support.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default SettlementSummary;
