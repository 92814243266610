import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SelectMUI from "@mui/material/Select";

const Select = ({
  label,
  options,
  value,
  onChange,
  sx = { minWidth: 120 },
  clearable = false,
}) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  if (!sx.minWidth) {
    sx.minWidth = 120;
  }
  return (
    <Box sx={sx}>
      <FormControl fullWidth size="small">
        <InputLabel>{label}</InputLabel>
        <SelectMUI value={value} label={label} onChange={handleChange}>
          {clearable && (
            <MenuItem key="none" value="">
              <em>None</em>
            </MenuItem>
          )}
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SelectMUI>
      </FormControl>
    </Box>
  );
};

export default Select;
