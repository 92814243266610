import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import {
  OrderHeader,
  OrderAlert,
  OrderSummaryCard,
  DeliveryManagement,
  Address,
  Payment,
  Refund,
  Settlement,
} from "../components/pages/OrderPage";
import OrderPageContextProvider from "../context/order-page-context";

const OrderPage = () => {
  return (
    <OrderPageContextProvider>
      <Header />
      <Container>
        <OrderHeader />
        <OrderAlert />
        <OrderSummaryCard />
        <DeliveryManagement />
        <Address />
        <Payment />
        <Refund />
        <Settlement />
      </Container>
    </OrderPageContextProvider>
  );
};

export default OrderPage;
