import { TextField } from "@mui/material";
import getPropertyValue from "./getPropertyValue";

const Mobile = ({ formik, label, state, disabled = false, ...restOfProps }) => {
  const { values, touched, errors, handleBlur, setFieldValue } = formik;

  const value = getPropertyValue(values, state) || "";
  const isTouched = getPropertyValue(touched, state);
  const error = getPropertyValue(errors, state);
  const showError = Boolean(isTouched && error);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 10) return;
    const result = inputValue.replace(/\D/g, "");
    setFieldValue(state, result);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      name={state}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={showError}
      helperText={showError ? error : " "}
      type="tel"
      disabled={disabled}
      sx={{ marginBottom: 1 }}
      {...restOfProps}
    />
  );
};

export default Mobile;
