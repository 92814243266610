import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import SearchInput from "../SearchInput";
import Select from "../Select";

const Filters = ({ statusOptions, handleFilterChange }) => {
  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");

  const orderIdSearchHandler = () => {
    handleFilterChange({ orderId, status });
  };

  const handleStatusChange = (status) => {
    setStatus(status);
    handleFilterChange({ orderId, status });
  };

  useEffect(() => {
    if (orderId === "") {
      handleFilterChange({ orderId, status });
    }
  }, [orderId]);

  return (
    <Box sx={{ margin: "20px 0px", display: "flex" }}>
      <SearchInput
        label={"Order ID"}
        value={orderId}
        setValue={setOrderId}
        sx={{ mr: 2 }}
        searchHandler={orderIdSearchHandler}
      />
      <Select
        label={"Status"}
        options={statusOptions}
        value={status}
        onChange={handleStatusChange}
        clearable={true}
        sx={{ maxWidth: 200 }}
      />
    </Box>
  );
};

export default Filters;
