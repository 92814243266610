import { Box, Chip, Typography } from "@mui/material";
import { orderStatusToMuiColor } from "../../../utils/mappings";
import { useOrderPageContext } from "../../../context/order-page-context";
import ProcessExpiredOrder from "./ProcessExpiredOrder";

const OrderHeader = () => {
  const { order } = useOrderPageContext();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 0px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Order</Typography>
        <Chip
          sx={{ marginLeft: "10px" }}
          size="small"
          label={order.finalStatus}
          color={orderStatusToMuiColor[order.finalStatus]}
        />
      </Box>
      {["pending", "active"].includes(order.status) &&
        order.finalStatus === "expired" && <ProcessExpiredOrder />}
    </Box>
  );
};

export default OrderHeader;
