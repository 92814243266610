import React, { useState } from "react";
import { Button, Card, CardContent, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import PaginatedFilterList from "../components/PaginatedFilterList/PaginatedFilterList";
import ColonText from "../components/ColonText";

import api from "../api";
import formatTimestamp from "../utils/formatTimestamp";

const SettlementsPage = () => {
  const navigate = useNavigate();
  const [settlements, setSettlements] = useState([]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Settlements</PageTitle>
        <PaginatedFilterList
          apiResponseKey={"settlements"}
          getListApi={api.settlements.getAllSettlements}
          statusOptions={[
            { value: "pending", label: "Pending" },
            { value: "completed", label: "Completed" },
            { value: "failed", label: "Failed" },
          ]}
          updateList={setSettlements}
          list={settlements}
          setList={setSettlements}
        >
          {settlements.map((settlement) => (
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <ColonText first={"Order ID"} second={settlement.order} />
                <ColonText
                  first={"Amount"}
                  second={"₹ " + settlement.settlementAmount}
                />
                <ColonText first={"Status"} second={settlement.status} />
                <ColonText
                  first={"Updated at"}
                  second={formatTimestamp(settlement.updatedAt)}
                />
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/settlements/${settlement._id}`)}
                  endIcon={<ArrowForward />}
                  sx={{ mt: 1 }}
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          ))}
        </PaginatedFilterList>
      </Container>
    </>
  );
};

export default SettlementsPage;
