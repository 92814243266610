import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import Header from "../components/Header/Header";
import Title from "../components/Title";

import api from "../api";
import handleError from "../utils/handleError";

const KitchenRegistrationPage = () => {
  const [kitchenRegistrations, setKitchenRegistrations] = useState([]);

  useEffect(() => {
    const getAllAppliedKitchenRegistrations = async () => {
      try {
        const res = await api.kitchenRegistrations.getAllKitchenRegistrations({
          filters: {
            status: "applied",
          },
        });
        setKitchenRegistrations(res.data.kitchenRegistrations);
      } catch (error) {
        handleError(error);
      }
    };
    getAllAppliedKitchenRegistrations();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title title={"Kitchen Registrations"} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kitchen Name</TableCell>
                <TableCell>Pincode</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kitchenRegistrations.map((registration) => (
                <TableRow key={registration._id}>
                  <TableCell>
                    <Link to={`/kitchen-registrations/${registration._id}`}>
                      {registration.name}
                    </Link>
                  </TableCell>
                  <TableCell>{registration.address.pincode}</TableCell>
                  <TableCell>{registration.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default KitchenRegistrationPage;
