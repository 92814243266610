import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import FormComponents from "../../FormComponents/FormComponents";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const validationSchema = yup.object({
  notes: yup.string(),
});

const ApproveButton = ({ fetchKitchenRegistration }) => {
  const params = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ notes }) => {
      try {
        await api.kitchenRegistrations.approve(params.id, {
          notes,
        });
        toast.success("Kitchen Registration approved");
        closeDialog();
        await fetchKitchenRegistration();
      } catch (error) {
        closeDialog();
        handleError(error);
      }
    },
  });

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    formik.setErrors({ notes: "" });
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={openDialog}
        endIcon={<DoneIcon />}
      >
        Approve
      </Button>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Are you sure you want to approve?</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} style={{ marginTop: "5px" }}>
            <FormComponents
              formik={formik}
              data={[
                {
                  type: "input",
                  label: "Notes (Optional)",
                  state: "notes",
                  multiline: true,
                  rows: 4,
                },
              ]}
            />
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button type="submit" color="success">
                Approve
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApproveButton;
