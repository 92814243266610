import { Typography } from "@mui/material";

import { useData } from "../context/data-context";
import withDataCheck from "../hoc/withDataCheck";

const CategoryTitle = ({ cuisine, mealType, mealTime, period }) => {
  const { cuisineLabels, mealTypeLabels, mealTimeLabels, periodLabels } =
    useData();
  return (
    <Typography variant="body1" style={{ marginBottom: "10px" }}>
      {cuisineLabels[cuisine]} {mealTypeLabels[mealType]}{" "}
      {mealTimeLabels[mealTime]}
      {period && `  - ${periodLabels[period]}`}
    </Typography>
  );
};

export default withDataCheck(CategoryTitle);
