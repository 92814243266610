import axios from "axios";
import getAll from "../utils/getAll";

let base = "/payments/";

const getAllPayments = getAll(base);

const getPayment = async ({ paymentId }) =>
  (await axios.get(base + paymentId + "?role=admin")).data;

const getAllUnprocessedExpiredPayments = async () =>
  (await axios.get(base + "expired/unprocessed")).data;

const processExpiredPayment = async ({ paymentId }) =>
  await axios.post(base + paymentId + "/processExpiredPayment");

const processAllExpiredPayments = async () =>
  (await axios.post(base + "processAllExpiredPayments")).data;

const payments = {
  getAllPayments,
  getPayment,
  getAllUnprocessedExpiredPayments,
  processExpiredPayment,
  processAllExpiredPayments,
};

export default payments;
