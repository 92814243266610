import TitleWithButton from "../../TitleWithButton";
import AddressSummary from "../../AddressSummary";
import { useOrderPageContext } from "../../../context/order-page-context";

const Address = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      <TitleWithButton title="Address" />
      <AddressSummary address={order.address} />
    </>
  );
};

export default Address;
