import React from "react";
import { Alert, Card, CardContent } from "@mui/material";

import ColonText from "../../ColonText";
import formatTimestamp from "../../../utils/formatTimestamp";

const RefundSummary = ({ refund }) => {
  const { refundAmount, status } = refund;

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <ColonText first={"Amount"} second={"₹" + refundAmount} />
        <ColonText first={"Status"} second={status} />
        {refund.status === "completed" && (
          <ColonText
            first={"Refund completed on"}
            second={formatTimestamp(
              refund.timeline[refund.timeline.length - 1].timestamp
            )}
          />
        )}
        {refund.status === "pending" && (
          <Alert severity="info">
            Your refund is currently being processed. Thank you for your
            patience.
          </Alert>
        )}
        {refund.status === "failed" && (
          <Alert severity="warning">
            We encountered an issue processing your settlement. Our team is
            actively working on resolving it. If the issue persists, please
            contact support.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default RefundSummary;
