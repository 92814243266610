import {
  Card,
  CardContent,
  List,
  ListItemText,
  Typography,
} from "@mui/material";

const OnlinePayment = ({ payment }) => {
  const {
    paymentGateway,
    orderId,
    paymentId,
    signature,
    receipt,
    isSignatureVerified,
    signatureVerifiedAt,
  } = payment.online;

  const list = [
    {
      primary: "Payment Gateway",
      secondary: paymentGateway,
    },
    {
      primary: "Order ID",
      secondary: orderId,
    },
    {
      primary: "Payment ID",
      secondary: paymentId,
    },
    {
      primary: "Signature",
      secondary: signature,
    },
    {
      primary: "Receipt",
      secondary: receipt,
    },
    {
      primary: "Signature Verified",
      secondary: isSignatureVerified ? "Yes" : "No",
    },
    {
      primary: "Signature Verified At",
      secondary: signatureVerifiedAt,
    },
  ];

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Payment Gateway Details
      </Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <List>
            {list.map(({ primary, secondary }) => (
              <ListItemText
                key={primary}
                primary={primary}
                secondary={secondary}
              />
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default OnlinePayment;
