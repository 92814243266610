import { Routes, Route, Navigate } from "react-router-dom";
import Protect from "./Protect";
import routes from "./routes";
import HomePage from "../pages/HomePage";
import AuthPage from "../pages/Auth/AuthPage";

const Router = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />} />
      <Route
        path="/"
        element={
          <Protect>
            <HomePage />
          </Protect>
        }
      />
      {routes.map((route) => {
        const { path, component } = route;
        return route.isProtected ? (
          <Route
            key={path}
            path={path}
            element={<Protect>{component}</Protect>}
          />
        ) : (
          <Route key={path} path={path} element={component} />
        );
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
