// useGoogleMapsAPI.js
import { useEffect } from "react";

const useGoogleMapsAPI = (callbackName) => {
  useEffect(() => {
    // Define the global callback function that will run when the script is loaded
    window[callbackName] = () => {
      // Your map initialization code here
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&callback=${callbackName}`;
    document.body.appendChild(script);

    return () => {
      // Cleanup
      delete window[callbackName];
      document.body.removeChild(script);
    };
  }, [callbackName]);
};

export default useGoogleMapsAPI;
