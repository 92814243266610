import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormComponents from "../../FormComponents/FormComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  accountNumber: Yup.string()
    .required("Account number is required")
    .matches(/^\d+$/, "Account number must contain only digits")
    .max(20, "Account number length shouldn't exceed 20"),
  ifsc: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code (eg: HDFC0009580)")
    .required("IFSC code is required"),
});

const PayoutRefund = ({ refund, fetchRefund }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      ifsc: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.refunds.retryPayoutRefund({
          refundId: refund._id,
          ...values,
        });
        await fetchRefund();
        toast.success("Payout refund created successfully");
        handleClose();
      } catch (error) {
        handleError(error);
        handleClose();
      }
    },
  });

  return (
    <>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        Payout
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Create payout refund</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 1 }}>
              <FormComponents
                data={[
                  {
                    type: "input",
                    label: "Account number",
                    state: "accountNumber",
                    inputType: "tel",
                  },
                  {
                    type: "input",
                    label: "IFSC",
                    state: "ifsc",
                    inputType: "text",
                  },
                ]}
                formik={formik}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default PayoutRefund;
