import { Container } from "@mui/material";
import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import {
  ProcessAllPayments,
  Payments,
} from "../components/pages/UnprocessedExpiredPaymentsPage";
import api from "../api";
import handleError from "../utils/handleError";
import { useEffect, useState } from "react";

const UnprocessedExpiredPaymentsPage = () => {
  const [payments, setPayments] = useState([]);

  const fetchPayments = async () => {
    try {
      const {
        data: { payments },
      } = await api.payments.getAllUnprocessedExpiredPayments();
      setPayments(payments);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Unprocessed Expired Payments</PageTitle>
        {payments.length > 0 && (
          <ProcessAllPayments fetchPayments={fetchPayments} />
        )}
        <Payments payments={payments} />
      </Container>
    </>
  );
};

export default UnprocessedExpiredPaymentsPage;
