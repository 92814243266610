import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import {
  DeliveryPageHeader,
  DeliveryStatusCard,
  MenuCard,
  Timeline,
  ExistingRating,
} from "../components/pages/DeliveryPage";
import DeliveryPageContextProvider from "../context/delivery-page-context";

const DeliveryPage = () => {
  return (
    <DeliveryPageContextProvider>
      <Header />
      <Container>
        <DeliveryPageHeader />
        <DeliveryStatusCard />
        <MenuCard />
        <Timeline />
        <ExistingRating />
      </Container>
    </DeliveryPageContextProvider>
  );
};

export default DeliveryPage;
