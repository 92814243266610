import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../components/Header/Header";
import PageTitle from "../components/PageTitle";
import {
  RetryRefund,
  RefundDetails,
  Reversals,
  Payouts,
  FetchLatestStatus,
} from "../components/pages/RefundPage";

import api from "../api";
import handleError from "../utils/handleError";

const RefundPage = () => {
  const { refundId } = useParams();
  const [refund, setRefund] = useState(null);

  const fetchRefund = useCallback(async () => {
    try {
      const {
        data: { refund },
      } = await api.refunds.getRefund({
        refundId,
      });

      setRefund(refund);
    } catch (error) {
      handleError(error);
    }
  }, [refundId]);

  useEffect(() => {
    fetchRefund();
  }, [fetchRefund]);

  if (!refund) return null;

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Refund</PageTitle>
        <RetryRefund refund={refund} fetchRefund={fetchRefund} />
        <FetchLatestStatus refund={refund} fetchRefund={fetchRefund} />
        <RefundDetails refund={refund} />
        <Reversals refund={refund} />
        <Payouts refund={refund} />
      </Container>
    </>
  );
};

export default RefundPage;
