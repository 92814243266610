import {
  Box,
  Card,
  CardContent,
  List,
  ListItemText,
  Typography,
} from "@mui/material";

import Timeline from "../../Timeline";

const SettlementDetails = ({ settlement }) => {
  const {
    order: orderId,
    _id: settlementId,
    orderAmount,
    totalDeliveriesCount,
    currency,
    pricePerDelivery,
    settlementDeliveriesCount,
    settlementAmount,
    settlementReason,
    settlementMode,
    status,
  } = settlement;

  const list = [
    {
      primary: "Order ID",
      secondary: orderId,
    },
    {
      primary: "Settlement ID",
      secondary: settlementId,
    },
    {
      primary: "Order Amount",
      secondary: `${currency} ${orderAmount}`,
    },
    {
      primary: "Total Order Deliveries",
      secondary: totalDeliveriesCount,
    },
    {
      primary: "Price Per Delivery",
      secondary: `${currency} ${pricePerDelivery}`,
    },
    {
      primary: "Total Settlement Deliveries",
      secondary: settlementDeliveriesCount,
    },
    {
      primary: "Settlement Amount",
      secondary: `${currency} ${settlementAmount}`,
    },
    {
      primary: "Reason",
      secondary: settlementReason,
    },
    {
      primary: "Status",
      secondary: status,
    },
  ];

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <List>
            {list.map(({ primary, secondary }) => (
              <ListItemText
                key={primary}
                primary={primary}
                secondary={secondary}
              />
            ))}
          </List>
          <Timeline timeline={settlement.timeline} withoutCard />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SettlementDetails;
