import axios from "axios";

let base = "/menuSchedules/";

const getMenuScheduleDays = async ({
  kitchenId,
  cuisine,
  mealType,
  mealTime,
}) =>
  (
    await axios.get(base + "menuScheduleDays", {
      params: {
        kitchenId,
        cuisine,
        mealType,
        mealTime,
      },
    })
  ).data;

const get = async ({ kitchenId, cuisine, mealType, mealTime, day }) =>
  (
    await axios.get(base + "menuSchedule", {
      params: {
        kitchenId,
        cuisine,
        mealType,
        mealTime,
        day,
      },
    })
  ).data;

const menuSchedules = {
  getMenuScheduleDays,
  get,
};

export default menuSchedules;
