import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";

import api from "../api";
import handleError from "../utils/handleError";

const AuthContext = createContext({
  token: "",
  isLoggedIn: false,
  user: {},
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const initialToken = localStorage.getItem("token");
  const [authState, setAuthState] = useState({
    token: initialToken,
    user: null,
    isLoggedIn: !!initialToken,
  });

  const setAxiosAuthHeader = useCallback((token) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, []);

  const loginHandler = useCallback(
    ({ token = null, user = null }) => {
      localStorage.setItem("token", token);
      setAxiosAuthHeader(token);
      setAuthState({
        token,
        user,
        isLoggedIn: true,
      });
    },
    [setAxiosAuthHeader]
  );

  const logoutHandler = useCallback(() => {
    localStorage.removeItem("token");
    setAxiosAuthHeader(null);
    setAuthState({
      token: null,
      user: null,
      isLoggedIn: false,
    });
  }, [setAxiosAuthHeader]);

  const loginBasedOnToken = useCallback(
    async (token) => {
      try {
        setAxiosAuthHeader(token);
        const {
          data: { user },
        } = await api.users.getMe();
        loginHandler({ token, user });
      } catch (err) {
        handleError(err);
        logoutHandler();
      }
    },
    [setAxiosAuthHeader, loginHandler, logoutHandler]
  );

  useEffect(() => {
    if (authState.token) {
      loginBasedOnToken(authState.token);
    } else {
      logoutHandler();
    }
  }, [authState.token, loginBasedOnToken, logoutHandler]);

  const contextValue = useMemo(
    () => ({
      ...authState,
      login: loginHandler,
      logout: logoutHandler,
    }),
    [authState, loginHandler, logoutHandler]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
