import { useCallback, useEffect, useMemo, useState } from "react";

import Pagination from "./Pagination";
import Filters from "./Filters";
import NoListFound from "../NoListFound";

const PaginatedFilterList = ({
  children,
  statusOptions,
  getListApi,
  apiResponseKey,
  list,
  setList,
}) => {
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = useMemo(() => 5, []);

  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");

  const handleFilterChange = useCallback(({ orderId, status }) => {
    setOrderId(orderId);
    setStatus(status);
    setPage(0);
  }, []);

  const fetchList = useCallback(
    async ({ page, orderId, status }) => {
      try {
        const filters = {};
        if (orderId) {
          filters.order = orderId;
        }
        if (status) {
          filters.status = status;
        }
        const response = await getListApi({
          page: page + 1,
          limit: rowsPerPage,
          filters: filters,
        });
        setList(response.data[apiResponseKey]);
        setTotalCount(response.data.totalCount);
      } catch (error) {
        setList([]);
        setTotalCount(0);
      }
    },
    [getListApi, rowsPerPage, apiResponseKey, setList]
  );

  useEffect(() => {
    fetchList({ page, orderId, status });
  }, [page, orderId, status, fetchList]);

  return (
    <>
      <Filters
        statusOptions={statusOptions}
        handleFilterChange={handleFilterChange}
      />
      {children}
      {list.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
        />
      )}
      {list.length === 0 && <NoListFound text={`No ${apiResponseKey} found`} />}
    </>
  );
};

export default PaginatedFilterList;
