import { Card, CardContent } from "@mui/material";

import DeliveryCalendar from "./DeliveryCalendar/DeliveryCalendar";
import DeliveryProgress from "./DeliveryProgress";

const DeliverySummary = ({ order }) => {
  return (
    <Card>
      <CardContent>
        <DeliveryProgress order={order} />
        <DeliveryCalendar order={order} />
      </CardContent>
    </Card>
  );
};

export default DeliverySummary;
