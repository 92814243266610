import { Typography } from "@mui/material";

const PageTitle = ({ children }) => {
  return (
    <Typography variant="h6" component="h2" textAlign={"center"} padding={1.5}>
      {children}
    </Typography>
  );
};

export default PageTitle;
