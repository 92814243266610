import axios from "axios";

const getAll =
  (path) =>
  async ({ page, sort, limit, fields = "", filters = {} } = {}) => {
    // Helper function to flatten nested objects
    const flattenObject = (obj, parentKey = "") => {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          return { ...acc, ...flattenObject(obj[key], newKey) };
        } else {
          return { ...acc, [newKey]: obj[key] };
        }
      }, {});
    };

    // Flatten the nested filters object
    const flattenedFilters = flattenObject(filters);

    // Constructing the filter string from the flattened filters object
    const filterString = Object.keys(flattenedFilters)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            flattenedFilters[key]
          )}`
      )
      .join("&");

    // Constructing the query string dynamically based on provided parameters
    const queryStringParams = [];
    if (page) queryStringParams.push(`page=${encodeURIComponent(page)}`);
    if (sort) queryStringParams.push(`sort=${encodeURIComponent(sort)}`);
    if (limit) queryStringParams.push(`limit=${encodeURIComponent(limit)}`);
    if (fields) queryStringParams.push(`fields=${encodeURIComponent(fields)}`);
    if (filterString) queryStringParams.push(filterString);

    queryStringParams.push("role=admin");

    const queryString =
      queryStringParams.length > 0 ? `?${queryStringParams.join("&")}` : "";

    // Making an asynchronous HTTP GET request using axios
    const response = await axios.get(`${path}${queryString}`);

    // Returning the data from the response
    return response.data;
  };

export default getAll;
