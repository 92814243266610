import { ListItemText, List as ListMui } from "@mui/material";

const List = ({ list }) => {
  return (
    <ListMui>
      {list.map(({ primary, secondary }) => (
        <ListItemText key={primary} primary={primary} secondary={secondary} />
      ))}
    </ListMui>
  );
};

export default List;
